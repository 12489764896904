import "./App.css";

function App() {
  return (
    <div className="App">
      <p className="works">It works!</p>
    </div>
  );
}

export default App;
